<script setup lang="ts">
import type { Market } from '@ocp-zmarta/content-sdk'
import { useZmartaAnalytics } from '@ocp-zmarta/content-sdk'
import { getLogger, getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent, ref, type PropType } from 'vue'

const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
const ZgCustomerLead = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgCustomerLead))

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

const props = defineProps({
  backgroundElement: { type: Boolean, default: true },
  illustration: { type: Boolean, default: true },
  title: { type: String, required: true },
  text: { type: String, default: '' },
  input: { type: Object as PropType<{ label: string, error: string, market: Market }>, required: true },
  buttonText: { type: String, required: true },
  titleCompleted: { type: String, default: '' },
  textCompleted: { type: String, required: true },
  titleCompletedFailed: { type: String, default: '' },
  textCompletedFailed: { type: String, required: true },
  fullFlip: { type: Boolean, default: false },
  disclaimer: { type: String, default: '' },
})

const ZGA = useZmartaAnalytics({ market: ctx?.market, brand: ctx?.brand })

const submittedCompleted = ref<'success' | 'error' | undefined>()
const _title = ref('')
const _message = ref('')

function onBlur () {
  void ZGA.event?.modal?.email({
    category: 'email-signup',
    label: 'email-field',
  })
}

async function submitLead (email: string) {
  await ZGA.event?.modal?.click({
    category: 'email-signup',
    label: 'email-signup',
  })

  try {
    const emailIsValid = await verifyEmailAddress(email)

    if (emailIsValid) {
      const response = await createCustomerLead(email)
      handleLeadSubmission(response.ok)
    } else {
      handleLeadSubmission(false)
    }
  } catch (error) {
    getLogger().error({ error }, 'Error during lead submission')
    handleLeadSubmission(false)
  }
}

function handleLeadSubmission (success: boolean) {
  submittedCompleted.value = success ? 'success' : 'error'
  _title.value = success ? props.titleCompleted : props.titleCompletedFailed
  _message.value = success ? props.textCompleted : props.textCompletedFailed
}

async function verifyEmailAddress (email: string): Promise<boolean> {
  const response = await fetch('/service/common-gateway/api/v1/customer/validate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      brand: ctx?.brand,
      market: ctx?.market,
    },
    body: JSON.stringify({ email }),
  })
  const data = await response.json()

  return data?.email === email
}

async function createCustomerLead (email: string): Promise<Response> {
  return await fetch('/service/common-gateway/api/v1/customer/addLead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      brand: ctx?.brand,
      market: ctx?.market,
    },
    body: JSON.stringify({ email, vertical: 'consumer-loan' }),
  })
}
</script>

<template>
  <zg-section>
    <zg-customer-lead
      :title="title"
      :title-submitted="_title"
      :text-submitted="_message"
      :input="input"
      :button-text="buttonText"
      :text="text"
      :disclaimer="disclaimer"
      illustration="co-applicant"
      :error="input.error"
      :label="input.label"
      :button-label="buttonText"
      :status-submitted="submittedCompleted"
      @submit-lead="submitLead"
      @blur="onBlur"
    />
  </zg-section>
</template>
